import { KeyboardArrowDown } from '@mui/icons-material';
import { MenuItem, TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export const CalcSelect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const onChange = (e) => {
    navigate(e.target.value + window.location.search);
  };
  return (
    <TextField
      select
      sx={{ svg: { color: '#0278FF' } }}
      SelectProps={{ IconComponent: KeyboardArrowDown }}
      defaultValue={location.pathname}
      onChange={onChange}
      label="Калькуляторы"
    >
      <MenuItem value={'/sales'}>Расчет тарифов для малого бизнеса</MenuItem>
      <MenuItem value={'/medium'}>Расчет тарифов для среднего бизнеса</MenuItem>
      <MenuItem value={'/client'}>Расчет переподписки для клиентского сервиса</MenuItem>
      <MenuItem value={'/dop'}>Дополнительные услуги</MenuItem>
    </TextField>
  );
};
