/* eslint-disable import/no-anonymous-default-export */
export default {
  'юридические услуги': [
    {
      id: 'legal-consultation-oral',
      name: 'Устная юридическая консультация',
      cost: 2000,
      full: 'Устная юридическая консультация',
    },
    {
      id: 'legal-consultation-written',
      name: 'Письменная юридическая консультация',
      cost: 2000,
      full: 'Письменная юридическая консультация',
    },
    {
      id: 'legal-template-selection',
      name: 'Подбор формы договора, документа',
      cost: 1000,
      full: 'Подбор шаблонной формы договора или документа',
    },
    {
      id: 'legal-contract-preparation',
      name: 'Подготовка договора',
      cost: 3000,
      full: 'Подготовка договора на базе стандартного имеющегося с внесением корректировок в основные условия), составление дополнительных соглашений к заключенным договорам и соглашений о расторжении заключенных договоров',
      suggestion:
        'На базе стандартного имеющегося с внесением корректировок в основные условия, а также составление дополнительных соглашений к заключенным договорам и соглашений о расторжении заключенных договоров',
    },
    {
      divider: 'Анализ норм договора',
      ids: ['legal-verbal-conclusion', 'legal-written-conclusion'],
    },
    {
      id: 'legal-verbal-conclusion',
      name: 'С устным заключением',
      cost: 3000,
      full: 'Анализ норм договора с устным заключением',
    },
    {
      id: 'legal-written-conclusion',
      name: 'С письменным заключением',
      cost: 3000,
      full: 'Анализ норм договора с письменным заключением',
    },
    {
      id: 'legal-disagreement-protocol',
      name: 'Протокол разногласий к договору',
      cost: 3000,
      full: 'Составление Протокола разногласий к договору',
    },
    { divider: '' },
    {
      id: 'legal-registration-branches',
      name: 'Гос-ная рег-я филиалов, представительств',
      cost: 10000,
      full: 'Подготовка документов для государственной регистрации филиалов, представительств (без подачи документов в налоговую)',
      suggestion: 'Подготовка документов без подачи документов в налоговую',
    },
    {
      id: 'legal-single-participant-resolution',
      name: 'Решения единственного участника ООО',
      cost: 3000,
      full: 'Оформление Решений единственного участника ООО (не связанной с регистрацией ООО)',
      suggestion: 'Оформление не связанной с регистрацией ООО',
    },
    {
      id: 'legal-assembly-protocols',
      name: 'Протоколы собраний участников ООО',
      cost: 3000,
      full: 'Оформление Протоколов общего собрания участников ООО (не связанной с регистрацией ООО)',
      suggestion: 'Оформление не связанной с регистрацией ООО',
    },
    {
      id: 'legal-okved-assistance',
      name: 'ОКВЭД',
      cost: 3000,
      full: 'Помощь в подборе ОКВЭД - до 5 штук (без подачи документов в налоговую)',
      suggestion: 'Помощь в подборе без подачи документов в налоговую',
    },
    {
      id: 'legal-egryul-changes',
      name: 'Документы для изменений в ЕГРЮЛ',
      cost: 5000,
      full: 'Подготовка документов для внесения изменений в сведения в ЕГРЮЛ о юридических лицах (смена директора, ОКВЭД, телефон, ошибки, без подачи документов в налоговую)',
      suggestion: 'Смена: директора, ОКВЭД, телефон, ошибки; без подачи документов в налоговую',
    },
    {
      id: 'legal-documents-change',
      name: 'Изменение учредительных документов',
      cost: 5000,
      full: 'Подготовка документов по внесение изменений в учредительные документы смена адреса в 1 этап (без подачи документов в налоговую)',
      suggestion: 'смена адреса в 1 этап',
    },
    {
      id: 'legal-address-change-stage-2',
      name: 'Смена адреса в 2 этапа',
      cost: 10000,
      full: 'Смена адреса в 2 этапа',
    },
    {
      id: 'legal-capital-increase',
      name: 'Увеличение уставного капитала',
      cost: 10000,
      full: 'Увеличение уставного капитала',
    },
    {
      id: 'legal-documents-check',
      name: 'Юр. проверка учредительных документов',
      cost: 5000,
      full: 'Юридическая проверка готовых учредительных документов клиента',
    },
    {
      id: 'legal-organization-liquidation',
      name: 'Ликвидация организации',
      cost: 15000,
      suggestion:
        'Без процедуры банкротства, без подготовки ликвидационных балансов и без публикаций в Вестнике государственной регистрации',
      full: 'Ликвидация организации без процедуры банкротства, без подготовки ликвидационных балансов и публикаций в Вестнике государственной регистрации',
    },
    {
      id: 'legal-egrip-changes',
      name: 'Документы для изменений в ЕГРИП',
      cost: 3000,
      suggestion:
        'Изменения сведений об ИП: паспортных данных, ОКВЭД; без подачи документов в налоговую',
      full: 'Подготовка документов по внесению изменений в сведения об индивидуальном предпринимателе в ЕГРИП (изменение паспортных данных, ОКВЭД) (без подачи документов в налоговую)',
    },
    {
      id: 'legal-business-liquidation',
      name: 'Ликвидация предпринимательской деятельности',
      cost: 5000,
      suggestion: 'Без решения вопросов с СФР',
      full: 'Ликвидация предпринимательской деятельности без решения вопросов с СФР',
    },
    {
      id: 'legal-case-analysis',
      name: 'Анализ судебного дела',
      cost: 10000,
      suggestion:
        'Изучение представленных материалов до 20 листов в общем объеме представленных документов',
      full: 'Анализ судебного дела (изучение представленных материалов до 20 листов в общем объеме представленных документов) ',
    },
    {
      id: 'legal-documents',
      name: 'Правовые документы',
      cost: 5000,
      suggestion: 'Заявления, жалобы, претензии, ходатайства, без участия в судебном процессе',
      full: 'Составление заявлений, жалоб, претензий, ходатайств (без участия в судебном процессе)',
    },
    {
      id: 'legal-power-of-attorney',
      name: 'Составление доверенностей',
      cost: 1000,
      suggestion: 'Для почты, налоговой, судов и др.',
      full: 'Составление доверенностей (для почты, налоговой, суды и др)',
    },
    {
      id: 'legal-order-open-branch',
      name: 'Приказ по открытию ОП',
      cost: 3000,
      full: 'Подготовка Приказа по открытию обособленного подразделения',
    },
    {
      id: 'legal-order-close-branch',
      name: 'Приказ по закрытию ОП',
      cost: 3000,
      full: 'Подготовка Приказа по закрытию обособленного подразделения',
    },
    {
      id: 'legal-analysis-local-acts',
      name: 'Юр. анализ локальных норм. актов',
      cost: 5000,
      full: 'Юридический анализ локальных нормативных актов (приказов, инструкций, положений)',
      suggestion: 'приказов, инструкций, положений',
    },
    {
      id: 'legal-individual-documents',
      name: 'Индивидуальные документы',
      cost: 1000,
      full: 'Разработка иных индивидуальных документов по деятельности организации',
    },
  ],
  // 'восстановление учета': [
  //   {
  //     id: 'legal-zero-reports-past-periods',
  //     name: 'Нулевые отчеты за прошлые периоды',
  //     cost: 1000,
  //     suggestion: 'Стоимость указана за один отчет',
  //     full: 'Нулевые отчеты за прошлые периоды\r\n*стоимость указана за один отчет',
  //   },
  //   {
  //     id: 'legal-data-transfer-1c',
  //     name: 'Перенос данных из иных учетных систем в 1С:Бухгалтерия Предприятия 8.3 без восстановления учета',
  //     cost: 0,
  //     tariff: 70,
  //     full: 'Перенос данных из иных учетных систем в 1С:Бухгалтерия Предприятия 8.3 без восстановления учета',
  //   },
  //   {
  //     id: 'legal-recovery-reporting-period',
  //     name: 'Восстановление учета в отчетный период',
  //     cost: 0,
  //     tariff: 70,
  //     full: 'Восстановление учета в отчетный период (январь, март, апрель, июль, октябрь) за прошлый квартал',
  //     suggestion:
  //       'в январе, марте, апреле, июле или октябре за прошлый квартал. Услуга оказывается в два этапа: Бесплатная подача нулевой отчетности или на основании имеющихся данных без анализа. Восстановление учета после отчетного периода.',
  //   },
  //   {
  //     id: 'legal-recovery-one-month',
  //     name: 'Восстановление учета одного месяца',
  //     cost: 0,
  //     tariff: 100,
  //     suggestion: 'Со сдачей квартальной и годовой отчетности (март, июнь, сентябрь, декабрь)',
  //     full: 'Восстановление учета одного месяца со сдачей квартальной и годовой отчетности (март, июнь, сентябрь, декабрь)',
  //   },
  //   {
  //     id: 'legal-recovery-incoming-balances',
  //     name: 'Восстановление входящих остатков',
  //     cost: 0,
  //     tariff: 100,
  //     suggestion: 'На первое число месяца начала даты обслуживания',
  //     full: 'Восстановление входящих остатков на первое число месяца начала даты обслуживания',
  //   },
  //   {
  //     id: 'legal-recovery-off-reporting-period',
  //     name: 'Восстановление учета',
  //     cost: 0,
  //     tariff: 70,
  //     suggestion: 'Не в отчетный период',
  //     full: 'Восстановление учета не в отчетный период',
  //   },
  //   {
  //     id: 'legal-tax-accounting-reporting',
  //     name: 'Подготовка налоговой и бухгалтерской отчетности',
  //     cost: 3000,
  //     suggestion:
  //       'На основании имеющихся данных Заказчика без анализа и восстановления; стоимость указана за один отчет',
  //     full: 'Подготовка налоговой и бухгалтерской отчетности на основании имеющихся данных Заказчика без анализа и восстановления\r\n*стоимость указана за один отчет',
  //   },
  //   {
  //     id: 'legal-income-expense-book',
  //     name: 'Книга доходов и расходов, декларации по УСН-Д',
  //     cost: 15000,
  //     suggestion: 'На основании банковской выписки без восстановления учета',
  //     full: 'Формирование Книги доходов и расходов, декларации по УСН (Доходы) на основании банковской выписки без восстановления учета',
  //   },
  //   {
  //     id: 'legal-labor-relations',
  //     name: 'Оформление трудовых отношений на предприятии',
  //     cost: 3000,
  //     suggestion:
  //       '(Подготовка полного пакета внутренних документов на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя)',
  //     full: 'Оформление трудовых отношений на предприятии (подготовка полного пакета внутренних документов, необходимых для осуществления деятельности предприятия) на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
  //   },
  // ],
  'налоговый и  бухучет': [
    {
      id: 'legal-express-audit-1c',
      name: 'Экспресс-аудит базы',
      suggestion: '1С: Бухгалтерия Предприятия 7.7, 8.2, 8.3',
      cost: 2000,
      full: 'Экспресс-аудит базы 1С: Бухгалтерия Предприятия 7.7, 8.2, 8.3',
    },
    {
      id: 'legal-primary-documents-input',
      name: 'Обработка и ввод первичных документов',
      cost: 2000,
      suggestion:
        'После 10 числа месяца за предыдущий месяц обслуживания, следующего за отчетным периодом',
      full: 'Обработка и ввод первичных документов после 10 числа месяца за предыдущий месяц обслуживания, следующего за отчетным периодом',
    },
    {
      divider: 'Корректировка или изменение сданной отчетности',
      suggestion: 'При изменении Заказчиком данных в документах или в учете',
    },
    {
      id: 'legal-vat-profit-quarter-2',
      name: 'за квартал по НДС и Прибыли',
      cost: 3000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (за квартал по НДС и Прибыли) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'legal-simplified-tax-system-2',
      name: 'УСН за налоговый период',
      cost: 5000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (УСН за налоговый период) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'legal-reports-assets-water-tax-1',
      name: 'в отчетах Имущество, водный налог',
      cost: 3000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (в отчетах Имущество, водный налог) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'legal-accounting-reports-1',
      name: 'бухгалтерской отчетности',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (бухгалтерской отчетности) при изменении Заказчиком данных в документах или в учете\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      divider: 'Корректировка сданной отчетности за период без ГБА',
      suggestion: 'Когда Заказчик не сопровождался в Главбух',
    },
    {
      id: 'legal-vat-profit-quarter-alt',
      name: 'за квартал по НДС и Прибыли',
      cost: 4000,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (за квартал по НДС и Прибыли) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'legal-simplified-tax-system-alt',
      name: 'УСН за налоговый период',
      cost: 5500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (УСН за налоговый период) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'legal-reports-assets-water-tax-alt',
      name: 'в отчетах Имущество, Водный налог',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (в отчетах Имущество, Водный налог) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'legal-accounting-reports-alt',
      name: 'бухгалтерской отчетности',
      cost: 3500,
      maxCoef: 6,
      full: 'Корректировка сданной отчетности (бухгалтерской отчетности) за период, когда Заказчик не сопровождался в Главбух Ассистент\r\n* в зависимости от объема предоставленных документов может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'document-preparation',
      name: 'Подготовка документов, отчетов по данным и по нерегламентированной форме Заказчика',
      cost: 2500,
      full: 'Подготовка документов, отчетов по данным и по нерегламентированной форме Заказчика',
    },
    {
      id: 'additional-account-maintenance',
      name: 'Ведение дополнительного р/с',
      cost: 1000,
      full: 'Ведение дополнительного расчетного счета',
    },
    {
      id: 'accounting-for-branch',
      name: 'Ведение учета по ОП',
      cost: 3000,
      full: 'Ведение учета по обособленному подразделению',
    },
    {
      id: 'statistical-reports',
      name: 'Отчеты в органы Статистики',
      cost: 1000,
      full: 'Подготовка отчетов в органы Статистики\r\n* зависимости от сложности отчета, может быть применен повышающий коэффициент от 2 до 7',
    },
    {
      id: 'vat-refund-documents',
      name: 'Документы на возмещение НДС',
      cost: 30000,
      suggestion: 'из бюджета',
      full: 'Подготовка документов на возмещение НДС из бюджета',
    },
    {
      id: 'report-6-ndfL',
      name: 'Отчеты 6-НДФЛ по ФЛ',
      cost: 0,
      suggestion: 'По которым заказчик является налоговым агентом',
      full: 'Подготовка отчетов 6-НДФЛ по физическим лицам (далее ФЛ), по которым Заказчик является налоговым агентом',
    },
    {
      id: 'responses-to-government-requests',
      name: 'Ответы на требования от гос органов',
      cost: 3000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался в Главбух Ассистент, без сдачи корректирующей отчетности.',
      full: 'Подготовка ответов на Требования от государственных органов за период, когда Заказчик не сопровождался в Главбух Ассистент, без сдачи корректирующей отчетности\r\n* зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'tax-optimization-consultation',
      name: 'Консультации по оптимизации налогообложения',
      cost: 10000,
      full: 'Консультации по вопросам оптимизации по налогообложению',
    },
    {
      id: 'managerial-accounting',
      name: 'Управленческий учет',
      suggestion: 'консультация, помощь в настройке, контроль данных',
      cost: 0,
      full: 'Управленческий учет (консультация, помощь в настройке, контроль данных)',
    },
    {
      id: 'foreign-economic-activity',
      name: 'ВЭД',
      cost: 0,
      tariff: 25,
      full: 'Внешнеэкономическая деятельность',
    },
    {
      id: 'non-server-accounting',
      name: 'Ведение учета в любых программах, размещенных не на сервере Исполнителя',
      cost: 0,
      tariff: 50,
      full: 'Ведение учета в любых программах, размещенных не на сервере Исполнителя',
    },
    {
      id: 'debt-collection',
      name: 'Сбор дебиторской задолженности',
      cost: 2.5,
      full: 'Сбор дебиторской задолженности',
    },
    {
      id: 'collection-of-primary-documents',
      name: 'Сбор первичных документов',
      cost: 2500,
      suggestion: 'с контрагентов',
      full: 'Сбор первичных документов с контрагентов',
    },
  ],
  зиК: [
    {
      id: 'salary-recalculation',
      name: 'Повторный расчет заработной платы, аванса, отпуска, больничного, увольнения',
      cost: 900,
      suggestion: 'В связи с внесенными изменениями по запросу Заказчика',
      full: 'Повторный расчет заработной платы, аванса, отпуска, больничного, увольнения в связи с внесенными изменениями по запросу Заказчика',
    },
    {
      id: 'report-correction',
      name: 'Корректировка сданной отчетности',
      cost: 5000,
      maxCoef: 6,
      suggestion: 'при изменении Заказчиком данных в документах или в учете',
      full: 'Корректировка сданной отчетности при изменении Заказчиком данных в документах или в учете\r\n* зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'report-correction-no-support',
      name: 'Корректировка сданной отчетности',
      cost: 3000,
      maxCoef: 6,
      suggestion: 'за период, когда Заказчик не сопровождался у Исполнителя',
      full: 'Корректировка сданной отчетности за период, когда Заказчик не сопровождался у Исполнителя\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'sfr-refund',
      name: 'Возмещение СФР по сотрудникам',
      cost: 0,
      tariff: 30,
      suggestion: 'которые трудоустроены в период, когда Заказчик не сопровождался у Исполнителя',
      full: 'Возмещение СФР по сотрудникам, которые трудоустроены в период, когда Заказчик не сопровождался у Исполнителя',
    },
    {
      id: 'employment-relationship',
      name: 'Оформление трудовых отношений на предприятии',
      cost: 2000,
      suggestion:
        '(Подготовка полного пакета внутренних документов, необходимых для осуществления деятельности предприятия) на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
      full: 'Оформление трудовых отношений на предприятии (подготовка полного пакета внутренних документов, необходимых для осуществления деятельности предприятия) на 1 сотрудника, принятого в период, когда Заказчик не сопровождался у Исполнителя',
    },
    {
      id: 'preparation-for-labor-inspection',
      name: 'Подготовка предприятия к проверке трудовой инспекцией',
      suggestion: 'анализ имеющихся документов, подготовка недостающих документов',
      cost: 10000,
      full: 'Подготовка предприятия к проверке трудовой инспекцией (анализ имеющихся документов, подготовка недостающих документов)',
    },
    {
      id: 'government-requests-preparation',
      name: 'Подготовка ответов на требования от гос органов',
      cost: 2000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался у Исполнителя, без сдачи корректирующей отчетности',
      full: 'Подготовка ответов на Требования от государственных органов за период, когда Заказчик не сопровождался у Исполнителя, без сдачи корректирующей отчетности * зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'staff-schedule-changes',
      name: 'Утверждение/внесение изменений в штатное расписание',
      cost: 4000,
      maxCoef: 6,
      suggestion:
        'когда Заказчик не сопровождался у Исполнителя, а так же в случае, если Заказчиком был утвержден проект штатного расписания и Исполнитель принял в работу',
      full: 'Утверждение/внесение изменений в штатное расписание за периоды, когда Заказчик не сопровождался у Исполнителя, а так же в случае, если Заказчиком был утвержден проект штатного расписания и Исполнитель принял в работу\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6\r\n**шаг – 1 000 ₽ за каждые 10 штатных должностей',
    },
    {
      id: 'job-descriptions',
      name: 'Должностные инструкции для сотрудников',
      cost: 2000,
      maxCoef: 6,
      suggestion: 'трудоустроенных до начала периода обслуживания у Исполнителя',
      full: 'Должностные инструкции для сотрудников, трудоустроенных до начала периода обслуживания у Исполнителя\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'additional-agreements',
      name: 'Дополнительные соглашения к трудовым договорам, приказы',
      cost: 500,
      suggestion: 'которые необходимо было оформить до начала периода обслуживания у Исполнителя',
      full: 'Дополнительные соглашения к трудовым договорам, приказы, которые необходимо было оформить до начала периода обслуживания у Исполнителя',
    },
    {
      id: 'employee-data-corrections',
      name: 'Корр-ки в личные данные сотрудников,',
      cost: 0,
      suggestion:
        'если сотрудники были приняты до начала периода обслуживания (внесение детей, семейного положения, образование и т.д.) по инициативе Заказчика',
      full: 'Внесение корректировок в личные данные сотрудников, если сотрудники были приняты до начала периода обслуживания (внесение детей, семейного положения, образование и т.д.) по инициативе Заказчика *шаг – 500 руб. за каждые 5 сотрудников',
    },
    {
      id: 'document-check',
      name: 'Проверка кадровых документов',
      cost: 500,
      suggestion:
        'трудовые договоры, дополнительные соглашения, оформленные в периоды до начала обслуживания у Исполнителя',
      full: 'Проверка кадровых документов (трудовые договоры, дополнительные соглашения) оформленные в периоды до начала обслуживания у Исполнителя',
    },
    {
      id: 'regulatory-check',
      name: 'Проверка нормативно-правовых актов клиента',
      cost: 500,
      maxCoef: 6,
      suggestion:
        'Подготовленных самим клиентом до начала периода обслуживания у Исполнителя (Правила внутреннего трудового распорядка, Положение об оплате труда, Штатное расписание, Положение об отпусках, Положение о командировках, Положение о премировании и т.д.)',
      full: 'Проверка нормативно-правовых актов клиента, подготовленных самим клиентом до начала периода обслуживания у Исполнителя (Правила внутреннего трудового распорядка, Положение об оплате труда, Штатное расписание, Положение об отпусках, Положение о командировках, Положение о премировании и т.д.)\r\n* в зависимости от объема запрашиваемой информации может быть применен повышающий коэффициент от 2 до 6',
    },
    {
      id: 'military-registration',
      name: 'Воинский учет',
      cost: 1500,
      suggestion:
        'без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж.',
      full: 'Воинский учет (без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж).',
    },
    {
      id: 'military-registration-docs',
      name: 'Документы для воинского учета',
      cost: 3500,
      full: 'Подготовка пакета документов для постановки на воинский учет',
    },
    {
      id: 'migration-registration',
      name: 'Миграционный учет',
      cost: 10000,
      suggestion:
        'без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж',
      full: 'Миграционный учет (без личного присутствия в гос. учреждениях, только подготовка документов и инструктаж).',
    },
    {
      id: 'personnel-accounting',
      name: 'Учет расчетов с персоналом по ОП',
      cost: 1500,
      suggestion: 'в том числе на Тарифах "Зарплата и Кадры"',
      full: 'Учет расчетов с персоналом по обособленным подразделениям, в том числе на Тарифах "Зарплата и Кадры"',
    },
  ],
  //   '1С': [
  //     {
  //       id: '1c-module-installation',
  //       name: 'Установка доп. модуля',
  //       cost: 0,
  //       suggestion: "Оплата Заказчиком лицензии на размещение дополнительного модуля на нашем сервере",
  //       full: 'Установка дополнительного платного модуля для 1С\r\nОплата Заказчиком лицензии на размещение дополнительного модуля на нашем сервере',
  //     },
  //   ],
  //   IT: [
  //     {
  //       id: 'non-standard-print-form',
  //       suggestion:
  //         'Разработка и добавление печатной формы в базу 1С, через расширение или внешнюю печатную форму',
  //       cost: 16000,
  //       name: 'Нетиповая печатная форма',
  //       full: 'Добавление не типовой печатной формы в 1С\r\nРазработка и добавление печатной формы в базу 1С, через расширение или внешнюю печатную форму',
  //     },
  //     {
  //       id: 'external-processing',
  //       name: 'Внешние обработки',
  //       suggestion: 'дополнительные, почасовая оплата работы специалиста',
  //       cost: 5000,
  //       full: 'Написание дополнительных внешних обработок в 1С\r\nПочасовая оплата работы специалиста',
  //     },
  //     {
  //       id: 'standard-sync',
  //       name: 'Стандартная синхронизация/интеграция с 1С:БП 8.3 ПРОФ',
  //       cost: 5000,
  //       suggestion: 'настройка типовых правил обмена, без учёта правки данных',
  //       full: 'Стандартная синхронизация/интеграция с 1С:БП 8.3 ПРОФ\r\nНастройка типовых правил обмена (без учёта правки данных)',
  //     },
  //     {
  //       id: 'simple-sync',
  //       name: 'Простая синхронизация/интеграция с 1С:БП 8.3 ПРОФ',
  //       cost: 15000,
  //       suggestion:
  //         'доработка существующих правил обмена между базой Бухгалтерия предприятия и любым продуктом 1С, под конкретные требования',
  //       full: 'Простая синхронизация/интеграция с 1С:БП 8.3 ПРОФ\r\nДоработка существующих правил обмена между базой Бухгалтерия предприятия и любым продуктом 1С, под конкретные требования',
  //     },
  //     {
  //       id: 'complex-sync',
  //       name: 'Сложная синхронизация/интеграция с 1С:БП 8.3 ПРОФ',
  //       cost: 38000,
  //       suggestion:
  //         'персональная разработка правил обмена между базой 1С:БП 8.3 ПРОФ и любым продуктом 1С',
  //       full: 'Сложная синхронизация/интеграция с 1С:БП 8.3 ПРОФ\r\nПерсональная разработка правил обмена между базой 1С:БП 8.3 ПРОФ и любым продуктом 1С',
  //     },
  //     {
  //       id: 'transition-77-to-83',
  //       name: 'Переход с 7.7 на 1С:БП 8.3 ПРОФ',
  //       cost: 1000,
  //       suggestion: 'стоимость зависит от наличия типовых правил переноса данных',
  //       full: 'Переход с базы 1С версии 7.7 на версию 1С:БП 8.3 ПРОФ\r\n Стоимость зависит от наличия типовых правил переноса данных',
  //     },
  //     {
  //       id: 'server-rental',
  //       name: 'Аренда серверного оборудования',
  //       cost: 5000,
  //       suggestion: 'ежемесячный платеж',
  //       full: 'Услуги аренды серверного оборудования\r\nЕжемесячный платеж',
  //     },
  //     {
  //       id: 'update-standard-config',
  //       name: 'Обновление типовой конфигурации до актуального релиза',
  //       cost: 500,
  //       suggestion: 'оплата производится за каждый промежуточный релиз',
  //       full: 'Обновление типовой конфигурации до актуального релиза\r\nОплата производится за каждый промежуточный релиз',
  //     },
  //     {
  //       id: 'update-custom-config',
  //       name: 'Обновление не типовой конфигурации до актуального релиза',
  //       suggestion: 'оплата производится за каждый промежуточный релиз',
  //       cost: 3000,
  //       full: 'Обновление не типовой конфигурации до актуального релиза\r\nОплата производится за каждый промежуточный релиз',
  //     },
  //     {
  //       id: 'fix-technical-errors',
  //       name: 'Исправление технических ошибок',
  //       cost: 2500,
  //       suggestion:
  //         'примеры: некорректные движения по регистрам, ошибки при формировании отчетов, не работоспособность имеющихся доп. обработок в базе или внешних инструментов, не проводятся документы, не открывается база. Почасовая оплата работы специалиста',
  //       full: 'Исправление технических ошибок в базе 1С (примеры: некорректные движения по регистрам; выпадение ошибок при формировании отчетов; не работоспособность имеющихся доп. обработок в базе или внешних инструментов, не проводятся документы; не открывается база).\r\nПочасовая оплата работы специалиста',
  //     },
  //     {
  //       id: 'edo-integration',
  //       name: 'Интеграция и настройка ЭДО Заказчика с 1С Исполнителя',
  //       suggestion:
  //         'разовый платеж, в зависимости от сложности интеграции и использования внешних обработок',
  //       cost: 4000,
  //       full: 'Интеграция и настройка ЭДО Заказчика с базой 1С Исполнителя.\r\nРазовый платеж, в зависимости от сложности интеграции и использования внешних обработок',
  //     },
  //     {
  //       id: 'data-export',
  //       name: 'Выгрузка данных из базы 1С:БП ПРОФ в базовую версию или ФРЕШ',
  //       suggestion:
  //         'при расторжении договора или при переходе на сервер Заказчика, за каждый факт обращения',
  //       cost: 4000,
  //       full: 'Выгрузка данных из базы 1С:БП ПРОФ в базовую версию или ФРЕШ\r\nПри расторжении договора или при переходе на сервер Заказчика, за каждый факт обращения',
  //     },
  //   ],
};
