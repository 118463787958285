/* eslint-disable no-useless-concat */
export function numberToWords(num) {
  if (num === 0 || num < 1) return 'ноль';

  const units = ['', 'один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять'];
  const teens = [
    '',
    'одиннадцать',
    'двенадцать',
    'тринадцать',
    'четырнадцать',
    'пятнадцать',
    'шестнадцать',
    'семнадцать',
    'восемнадцать',
    'девятнадцать',
  ];
  const tens = [
    '',
    'десять',
    'двадцать',
    'тридцать',
    'сорок',
    'пятьдесят',
    'шестьдесят',
    'семьдесят',
    'восемьдесят',
    'девяносто',
  ];
  const hundreds = [
    '',
    'сто',
    'двести',
    'триста',
    'четыреста',
    'пятьсот',
    'шестьсот',
    'семьсот',
    'восемьсот',
    'девятьсот',
  ];
  const thousands = ['', 'тысяча', 'тысячи', 'тысяч'];
  const millions = ['', 'миллион', 'миллиона', 'миллионов'];
  const billions = ['', 'миллиард', 'миллиарда', 'миллиардов'];

  function getUnitName(unit, index) {
    if (index === 1) {
      if (unit === 1) return 'одна';
      if (unit === 2) return 'две';
    }
    return units[unit];
  }

  function getThousandsName(number) {
    if (number > 4 || number === 0) return thousands[3];
    if (number > 1 && number < 5) return thousands[2];
    return thousands[1];
  }

  function getMillionsName(number) {
    if (number > 4 || number === 0) return millions[3];
    if (number > 1 && number < 5) return millions[2];
    return millions[1];
  }

  function getBillionsName(number) {
    if (number > 4 || number === 0) return billions[3];
    if (number > 1 && number < 5) return billions[2];
    return billions[1];
  }

  function convertHundreds(num, index) {
    let str = '';
    str += (hundreds[Math.floor(num / 100)] || '') + ' ';
    num %= 100;
    if (num > 10 && num < 20) {
      str += teens[num - 10] + ' ';
    } else {
      str += (tens[Math.floor(num / 10)] || '') + ' ';
      str += (getUnitName(num % 10, index) || '') + ' ';
    }
    return str.trim();
  }

  let result = '';
  let billionPart = Math.floor(num / 1e9);
  if (billionPart > 0) {
    result += convertHundreds(billionPart, 0) + ' ' + getBillionsName(billionPart % 10) + ' ';
  }
  num %= 1e9;

  let millionPart = Math.floor(num / 1e6);
  if (millionPart > 0) {
    result += convertHundreds(millionPart, 0) + ' ' + getMillionsName(millionPart % 10) + ' ';
  }
  num %= 1e6;

  let thousandPart = Math.floor(num / 1e3);
  if (thousandPart > 0) {
    result += convertHundreds(thousandPart, 1) + ' ' + getThousandsName(thousandPart % 10) + ' ';
  }
  num %= 1e3;

  if (num > 0) {
    result += convertHundreds(num, 0) + ' ';
  }

  return result.trim();
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function calculateDiscountedPrice(originalPrice, discount) {
  if (!discount) return originalPrice;
  const discountedPrice = originalPrice * (1 - discount / 100);
  return discountedPrice;
}

export function copyToBuffer(result) {
  if (typeof navigator.clipboard !== 'undefined') navigator.clipboard.writeText(result);
  else {
    var tempInput = document.createElement('input');
    tempInput.style.position = 'absolute';
    tempInput.style.left = '-9999px';

    // Помещаем текст во временное поле
    tempInput.value = result;
    document.body.appendChild(tempInput);

    // Выделяем текст во временном поле
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Для мобильных устройств

    // Копируем текст в буфер обмена
    document.execCommand('copy');

    // Удаляем временное поле
    document.body.removeChild(tempInput);

    // Сообщаем пользователю об успешном копировании
  }
  alert('Текст скопирован: ' + result);
}
