import { ArrowBack, ArrowBackRounded } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

export const BackBtn = () => {
  const onClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const href = urlParams.get('href');
    if (href) {
      window.location.href = href;
    } else {
      window.location.href = 'https://arm.gba.guru/';
    }
  };
  return (
    <Button
      sx={{
        alignSelf: 'self-start',
        mb: 2,
        textTransform: 'none',
        height: '46px',
        display: 'flex',
        alignItems: 'center',
        gap: '9px',
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: 400,
        backgroundColor: '#EAECF1',
        color: '#515568',
        borderRadius: '20px',
        padding: '10x 9px',
        width: '203px',
      }}
      onClick={onClick}
    >
      <Box
        component="span"
        display="flex"
        alignItems="center"
        justifyItems="center"
        sx={{
          backgroundColor: '#474747',
          color: 'white',
          borderRadius: '50%',
          width: '28px',
          height: '28px',
        }}
      >
        <ArrowBack sx={{ fontSize: '16px', ml: 0.8 }} />
      </Box>
      Назад в ГБА АРМ
    </Button>
  );
};
